.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.border-color{
  padding : 3px;
  border-radius: 20%;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to right, #EDDE5D  51%, #F09819  100%)5;
}


.btn-grad {
    background-image: linear-gradient(to right, #F09819 0%, #EDDE5D  51%, #F09819  100%);
    /* margin: 10px; */
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
  .glow {
    font-size:3rem;
    color:white;
    font-family: 'Merienda', cursive;
    animation-name:glow;
    animation-duration:3s;
    animation-iteration-count:infinite;
    animation-direction:alternate;
  }

  @keyframes glow{
    from{text-shadow:0px 0px 5px #fff,0px 0px 5px #614ad3;}
    to{text-shadow:0px 0px 20px #fff,0px 0px 20px #614ad3;}
  }

  

  .intro h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 82px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: #fff;
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 auto;
    margin-bottom: 60px;
  }


  li>a:hover , li>a:focus {
    color: #23527c;
    text-decoration: underline;
  }

  li>a:active,  li>a:hover {
    outline: 0;
    outline-color: initial;
    outline-style: initial;
    outline-width: 0px;
}
  
  


.social ul li {
   display: inline-block;
  /* margin: 0 20px; */
}

.social a{
  text-decoration: none;
}


.facebook :hover{
  color: #608dfd;
  text-decoration: none;
}

.youtube :hover{
  color: #FF0000;
  text-decoration: none;
}

.twitter :hover{
  color: #1DA1F2;
  text-decoration: none;
}

.chooseList>li{
  list-style: none;
}

.chooseList>:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
  

.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
  

#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #031194 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

.cardBackground {
  background: linear-gradient(to bottom, #031194 0%, #5ca9fb 100%);
}


